.tabela-otimizacao-receita {

    position: relative;

    .esconde-bordas {
        position: fixed;
        background-color: #e9ecef;
        width: 100%;
        height: 76.25px;
        border-top: 1px solid darkgray;
        border-bottom: 1px solid darkgray;
        z-index: 6;

    }

    .somatorio {
        th {
            text-align: end;
            line-height: 14px;
        }
    }

    font-size: 14px;
    /* Define altura fixa para a tabela e habilita scroll */
    position: relative;
    overflow: auto;
    /* Scroll horizontal e vertical */

    /* Cabeçalho fixo no topo */
    thead th {
        position: sticky;
        top: 0;
        background-color: #e9ecef;
        z-index: 8;
        /* Garante que o cabeçalho fique acima da coluna fixa */
        /* Evita quebra de texto */
    }

    /* Primeira linha do cabeçalho */
    thead tr:first-child th {
        top: 0;
        /* Linha superior fixa no topo */
    }

    /* Segunda linha do cabeçalho */
    thead tr:nth-child(2) th {
        top: 28.67px;
        /* Desloca abaixo da primeira linha (ajuste conforme a altura da célula) */
    }

    /* Segunda linha do cabeçalho */
    thead tr:nth-child(3) th {
        top: 57px;
        /* Desloca abaixo da primeira linha (ajuste conforme a altura da célula) */
    }

    /* Coluna fixa à esquerda */
    .fixed-column {
        position: sticky;
        left: 0;
        background-color: #e9ecef;
        /* Garante que fique abaixo do cabeçalho */
        /* Evita quebra de texto */
        /* Define uma borda clara para destacar */
        width: 280px;
        min-width: 280px;
        max-width: 280px;
        z-index: 10;
    }

    .fixed-column::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        background-color: darkgray;
    }

    .table-bordered > :not(caption) > * {
        border-width:0 !important;
    }

    .grupo::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 1px;
        z-index: 1;
        background-color: #8080808e !important;
    }

    .grupo-linha {
        position: relative;
        background-color: red !important;

    }


    /* Ajustes para alinhamento perfeito */
    tbody td.fixed-column {
        z-index: 2;
        /* Garante que não sobreponha o cabeçalho */
    }

    /* Responsividade para telas menores */
    @media (max-width: 768px) {
        height: auto;
        /* Permite que a tabela cresça automaticamente */
        overflow-x: scroll;
        /* Scroll horizontal */


        table td,
        table th:not(.fixed-column) {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
    }


    /* Define largura fixa e consistente para as colunas (exceto a primeira) */
    table td,
    table th:not(.fixed-column) {
        width: 100px;
        /* Largura uniforme */
        min-width: 100px;
        /* Garante largura mínima para responsividade */
        max-width: 100px;
        /* Mantém largura fixa */
        text-align: center;
        /* Centraliza o texto */
        // white-space: nowrap;
        /* Evita quebra de texto */
        overflow: hidden;
        /* Esconde texto longo */
        text-overflow: ellipsis;
        /* Adiciona "..." em textos longos */
    }
    .pequena {
        width: 70px !important;
        min-width: 70px !important;
        max-width: 70px !important;
    }
}

.ag-cell-last-left-pinned,
.agTextColumnFilter {
    .ag-header-cell-comp-wrapper {
        justify-content: flex-start;
    }

    text-align: start !important;

}


.custom-header {
    text-align: center;
    white-space: normal;
    line-height: 1.2;
    .ag-header-cell-comp-wrapper {
        justify-content: flex-end;
    }

    text-align: end !important;

}


.line2::after {
    content: "";
    position: absolute;
    top: 0;
    right: -0.05rem;
    height: 100%;
    width: 0.08rem;
    background-color: darkgray;
}


.line::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    /* Ajuste a posição da borda à direita */
    height: 100%;
    width: 0.07rem;
    background-color: darkgray;
}


.ag-pinned-left-header {
    .ag-floating-filter:nth-child(2)::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 0.07rem;
        background-color: darkgray;
    }
}

.ag-header-viewport {
    .ag-floating-filter:nth-child(1)::after,
    .ag-floating-filter:nth-child(4)::after,
    .ag-floating-filter:nth-child(7)::after,
    .ag-floating-filter:nth-child(8)::after,
    .ag-floating-filter:nth-child(11)::after,
    .ag-floating-filter:nth-child(14)::after,
    .ag-floating-filter:nth-child(21)::after
    {
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        /* Ajuste a posição da borda à direita */
        height: 100%;
        width: 0.07rem;
        background-color: darkgray;
    }
}

.ag-row:nth-child(odd) {
    background-color: #f9f9f9; /* Cor para linhas ímpares */
  }
  
  .ag-row:nth-child(even) {
    background-color: #ffffff; /* Cor para linhas pares */
  }