/* import bootstrap to set changes */
@import "/src/assets/styles/fonts.scss";
@import "/src/assets/styles/config.scss";
@import "/src/assets/styles/bootstrap-utils.scss";
@import "./assets/styles/config.scss";
@import "~react-loading-skeleton/dist/skeleton.css";
@import "~bootstrap/scss/bootstrap";

.padding-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

@for $i from 1 through 9 {
  $bg-color: map-get($primary-colors, $i * 100);
  $hv-color: map-get($primary-colors, ($i + 1) * 100);

  .bg-primary-#{$i * 100} {
    background-color: $bg-color !important;
    --bs-table-accent-bg: transparent !important;

    td {
      background-color: $bg-color !important;
      --bs-table-accent-bg: transparent !important;
    }

    .form-control:focus,
    .form-control-sm:focus {
      border-color: $primary !important;
      box-shadow: none !important;
    }
  }

  .bg-primary-#{$i * 100}:hover {
    background-color: $hv-color !important;

    td {
      background-color: $hv-color !important;
    }
  }
}

.form-control {
  background: #ffffff;
  border: 2px solid #ced4da;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08);
  padding: 10px 16px;
  border-radius: 8px;
  min-width: 80px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 14px;
  line-height: 24px;
}

.form-control-sm {
  @extend .form-control;
  padding: 8px 12px;
  line-height: 14px;
}

.p-multiselect {
  font-family: "Barlow", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
  background: #ffffff !important;
  border: 2px solid #ced4da !important;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08) !important;
  border-radius: 8px !important;
  width: 100%;
  
}
ol,
ul {
  padding-left: 0rem !important;
}

.p-multiselect-label {
  padding: 6px 10px 6px 16px !important;
}

.p-multiselect-clear-icon {
  zoom: 0.9;
}


.p-inputtext {
  font-family: "Barlow", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px;

  padding: 8px 30px 8px 16px !important;
  border: 2px solid #ced4da !important;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08) !important;
  border-radius: 8px !important;
}

.p-dropdown {
  width: 100%;
  border: 2px solid #ced4da !important;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08) !important;
  border-radius: 8px !important;

  .p-inputtext {
    padding: 6px 16px !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.p-dropdown-panel {
  border: 2px solid #ced4da !important;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08) !important;
  border-radius: 8px !important;
  z-index: 1200 !important;

}

.p-inputtext:focus {
  box-shadow: none !important;
  border-color: $tertiary !important;
}

.p-multiselect-close:focus {
  box-shadow: none !important;
}

.p-multiselect-panel {
  z-index: 1200 !important;
  width: 400px;
  border: 2px solid #ced4da !important;
  border-radius: 8px !important;
}


.p-component {
  font-family: "Barlow", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px;

}


.p-multiselect-item.p-highlight {
  background: $tertiary-300 !important;
}

.p-multiselect-item.p-highlight {
  color: #212529 !important;
}

.p-dropdown-item.p-highlight {
  color: #212529 !important;
  background: $tertiary-300 !important;
}

.p-checkbox-box.p-highlight {
  border-color: $tertiary !important;
  background: $tertiary !important;
}

.p-checkbox-box.p-focus,
.form-check-input:focus {
  border-color: $tertiary !important;
  box-shadow: none !important;
}

.p-dropdown-icon {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #000000;
  color: #ffffff;
  width: 24px;
}

.page-link:focus {
  box-shadow: none !important;
}

.top-minus-1 {
  top: -1px !important;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  font-weight: 600;
}

.form-control:focus {
  border-color: $tertiary !important;
  box-shadow: none !important;
}

.form-select {
  border: 2px solid #ced4da;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08);
  line-height: 14px;
  font-size: 14px;
  height: 44px;
}

.form-select:focus {
  border-color: $tertiary !important;
  box-shadow: none !important;
}

.rbt-input-multi.focus {
  border-color: $tertiary !important;
  box-shadow: none !important;
}

.rbt-token {
  background-color: $tertiary !important;
  color: #fff !important
}

.btn-close:focus {
  box-shadow: none !important;
}

.baixar-bnt {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  border-radius: 30px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10px;

  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.cicle {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
}

.vertical-align {
  display: flex;
  align-items: center;

}

.table {
  th {
    line-height: 24px;
    background-color: transparent;
  }

  td {
    line-height: 24px;
  }

}

.btn-abrir-rotacao {
  transform: rotate(180deg);
  transition: transform 0.3s;

}

.btn-fechar-rotacao {
  transform: rotate(0);
  transition: transform 0.3s;
}

.transparente {
  background-color: transparent !important;
}
.transparent,[transparent] {
  background-color: transparent;
  --background-color: transparent;
}

@media (min-width: 992px) {
  .row-cols-lg-7>* {
    flex: 0 0 auto;
    width: 14.2857142857%;
  }
}


.mesma-linha {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

td {
  vertical-align: middle;
  text-align: center;
}

th {
  vertical-align: middle;
}



.ag-header-cell {
  text-align: center;
  vertical-align: middle;
  font-size: 12px; /* Diminui o tamanho da fonte */
  // line-height: 12px; /* Ajusta a altura das linhas */
  padding-left: 5px;
  padding-right: 5px;
  line-height: 17px;
}
.ag-floating-filter{
  padding: 4px; /* Ajusta o espaçamento interno */

}
.ag-header-group-cell{

  text-align: center;
  vertical-align: middle;
  font-size: 12px; /* Diminui o tamanho da fonte */
  // padding: 4px; /* Ajusta o espaçamento interno */
  line-height: 20px; /* Ajusta a altura das linhas */
  justify-content: center;

  .ag-header-group-cell-label{

    align-items: center;
    justify-content: center;
  }

}
.ag-input-field-input{
  height: 20px !important;  /* Ajuste a altura */
  font-size: 12px !important; /* Ajuste o tamanho da fonte */
}	
.ag-header-cell-text{
  text-align: center;

}
//  .ag-header-row{
//   background-color: #FAFAFB !important;
//   z-index: 21;
// }
.ag-header.ag-header-allow-overflow .ag-header-row[aria-rowindex="2"]{
  background-color: #FAFAFB !important;
  z-index: 21;
  border-bottom: 1px solid color-mix(in srgb, transparent, var(--ag-foreground-color) 15%);
}

.custom-header{
  .ag-header-cell-label{
    justify-content: center !important;

  }
  
}


.ag-theme-quartz {
  .ag-row {
    line-height: 1; // Ajusta o espaçamento entre as linhas
    height: 24px; // Define a altura das linhas
  }

  .ag-cell {
    padding: 2px 4px; // Reduz o padding interno das células
  }

  // .ag-header-cell {
  //   height: 28px; // Opcional: reduz a altura do cabeçalho para combinar
  // }
}