@import "../../assets/styles/config.scss";

.v-100 {
    .seta {
        $original-color: map-get($red-colors, 1000);
        color:  $original-color !important;
    }
}

.v-75 {
    .seta {
        $original-color: map-get($red-colors, 900);
        color:  $original-color !important;
    }
}

.v-50 {
    .seta {
        $original-color: map-get($red-colors, 800);
        color: $original-color !important;
    }
}

.v-25 {
    .seta {
        $original-color: map-get($red-colors, 700);
        color: $original-color !important;
    }
}

.v-0{
    .seta {
        $original-color: map-get($red-colors, 600);
        color: $original-color !important;
    }
}

.v--0 {
    .seta {
        $original-color: #DEE2E6;
        color: $original-color !important;
    }
}

.v0 {
    .seta {
        $original-color: map-get($green-colors, 600);
        color: $original-color !important;
    }
}

.v25 {
    .seta {
        $original-color: map-get($green-colors, 700);
        color: $original-color !important;
    }
}

.v50 {
    .seta {
        $original-color: map-get($green-colors, 800);
        color:  $original-color !important;
    }
}

.v75 {
    .seta {
        $original-color: map-get($green-colors, 900);
        color: $original-color !important;
    }
}

.v100 {
    .seta {
        $original-color: map-get($green-colors, 1000);
        color: $original-color !important;
    }
}

.seta{
    min-width: 53px;
}


// .v-100 {
//     .seta {
//         $original-color: #F49BA1;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v-75 {
//     .seta {
//         $original-color: #E8A695;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v-50 {
//     .seta {
//         $original-color: #FFD4B0;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v-25 {
//     .seta {
//         $original-color: #FFEEA3;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v25 {
//     .seta {
//         $original-color: #D4DEBA;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v50 {
//     .seta {
//         $original-color: #D0E39D;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }

// }

// .v75 {
//     .seta {
//         $original-color: #BAE3BC;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v100 {
//     .seta {
//         $original-color: #8DEBA6;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v0 {
//     .seta {
//         $original-color: #b8b8b8;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v25 {
//     .seta {
//         $original-color: #F49BA1;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v50 {
//     .seta {
//         $original-color: #FFD4B0;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }

// }

// .v75 {
//     .seta {
//         $original-color: #D0E39D;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }

// .v100 {
//     .seta {
//         $original-color: #A3E2B4;
//         background-color: $original-color !important;
//         color: $original-color !important;
//     }
// }