.Kpis {
    .grafico {
        // height: 300px;
    }

    .btn-edit {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        h6 {
            margin: 0;
        }
    }

    .chart-container {
        height: 30vh;

    }

    .mapa {
        .chart-container {
            height: calc(30vh + 64px);
        }
    }

    .div-chart {
        height: 100%;
    }

    .card-pai {
        background-color: #fdecea;
        position: relative;
        // .grafico:nth-child(odd) {
        //     background-color: transparent; /* Cor para linhas ímpares */
        //     border: none;
        //   }

        .grafico-transparente {
            background-color: transparent;
            /* Cor para linhas ímpares */
            border: none;
        }
    }

    .familia-section {
        position: absolute;
        left: 0px;
        z-index: 2;
        top: 12px;
    }
    // .card-body{
    //     padding: 16px;
    // }
}

.height-100p {
    height: 100%;
}

.mapaContainer {
    .modal-xl {
        max-width: 60vw !important;
    }

    .modal-body {
        height: 85vh;
    }

}